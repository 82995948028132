<template>
  <v-container fluid fill-height class="pa-0">
    <JitsiMeet
      v-if="this.jwt"
      :lobbyName="$route.params.lobbyName"
      :jwt="this.jwt"
    >
    </JitsiMeet>
  </v-container>
</template>

<script>
import JitsiMeet from "../components/global/JitsiMeet.vue";
export default {
  components: { JitsiMeet },
  props: {
    lobbyName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    jwt: "",
  }),

  mounted() {
    this.generateToken();
  },
  methods: {
    generateToken() {
      return this.$api
        .post("/meetings/room-subject-meetings/access-token/", {
          lobby_name: this.lobbyName,
        })
        .then((res) => (this.jwt = res.data.token))
        .catch((err) => {
          if (this.$route.query.jwt) {
            this.jwt = this.$route.query.jwt;
          }
        });
    },
  },
};
</script>

<style>
</style>